.loading {
    position: absolute;
}
.loadingLogin {
    position: fixed;
}
.loading {
  min-height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,.5);
  z-index: 99999;
  -webkit-animation: fadein .3s ease-in ;
}
.loading.hide {
    -webkit-animation: fadeout .3s ease-out ;
    opacity: 0;
    visibility: hidden;
}
.loading-wheel {
    -webkit-animation: spin 1s linear infinite;
    background-image : url(/static/media/logo-loading.25f2fe44.png);
    position: absolute;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover;
    position: absolute;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    margin-top: -50px;
    margin-left: -50px;
}
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0);
    }
    100% {
        -webkit-transform: rotate(-360deg);
    }
}
@-webkit-keyframes fadein {
    0% { opacity: 0; }
    100% { opacity: 1; }
}
@-webkit-keyframes fadeout {
    0% { opacity: 1; }
    100% { opacity: 0; }
}

.authBox {
  width: 350px;
  margin-top: 100px;
}
.authLogo {
  height: 70px;
  margin-bottom: 20px;
}
.authLabel {
  margin-bottom: 15px;
}
.inputFields {
  height: 60px;
  font-weight: 500;
  border-radius: 10px;
  border-width: 2px;
  padding-left: 20px;
}
.inputFields::-webkit-input-placeholder {
  color: #BBBBBB;
}
.inputFields::-ms-input-placeholder {
  color: #BBBBBB;
}
.inputFields::placeholder {
  color: #BBBBBB;
}
.inputFields:active,  .inputFields:focus {
  box-shadow: 0 0 4px 2px #EAA39D !important;
  border-color: #BBBBBB;
}
.loginButton, .loginButton:hover, .loginButton:hover:disabled, .navLogoutButton:active:focus {
  height: 60px;
  font-weight: 500;
  font-size: 1em;
  color: black !important;
  border-radius: 10px;
  border-width: 2px;
  background-color: #EAA39D !important;
  border-color: #ced4da !important;
  box-shadow: none !important;
}
.loginButton:hover {
  background-color: #e88178 !important;
}

.ReactNavigationControllerView,
.ReactNavigationControllerViewContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: inherit;
}

.ReactNavigationControllerView {
  display: flex;
}
.ReactNavigationController {
  position: relative;
  flex: 1 0;
}
.ReactNavigationControllerViewContent {
  color: white;
  display: flex;
  flex-direction: column;
}

.submissionModal h6 {
  font-size: 12px;
  font-weight: 400;
}
.submissionModalBox {
  width: 650px;
}

.AsyncContainer {
  background-color: #EDECED;
  min-height: 100%;
}
.logContainer {
  background-color: #EDECED;
  min-height: 100%;
}
.logFormContainer {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}
.formLabel {
  padding-top: 15px;
  padding-left: 10px;
  margin-bottom: 10px;
	font-size: 20px !important;
}
a.nav-item.nav-link {
  color: black;
  font-weight: 600;
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
}
a.nav-item.nav-link.active {
  text-decoration: none;
  border-color:  #e9ecef #e9ecef transparent !important;
}
.nav-tabs {
  border-bottom: none !important;
  border-width: 0px;
}
/* Custom column sizing */
.custcol-1-half-left-fix {
  margin-left: 19%;
  flex: 0 0 13.5% !important;
  max-width: 13.5% !important;
}
.custcol-1-half {
    flex: 0 0 12% !important;
    max-width: 12% !important;
}
.overlined-col {
  border-top-width: 2px ;
  border-color: gray;
  padding-top: 4px;
  border-top-style: solid;
}
.overlined-col-last {
  border-top-width: 2px ;
  border-color: gray;
  padding-top: 4px;
  border-top-style: solid;
  padding-right: 50px;
}
.overlined-col-first {
  border-top-width: 2px ;
  border-color: gray;
  padding-top: 4px;
  border-top-style: solid;
  padding-left: 60px;
}
.childBoxPlusSign {
  position: absolute;
  right: 47%;
  text-decoration: none !important;
  color: gray;
  font-size: 45px;
  font-weight: 600;
  bottom: -52px;
  z-index: 999;
}
.childBoxEqualSign {
  position: absolute;
  right: 40%;
  text-decoration: none !important;
  color: gray;
  font-size: 45px;
  font-weight: 600;
  bottom: -52px;
  z-index: 999;
}
.total-col  {
  align-self: flex-end;
}
.childBoxCalculationBox {
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  display: flex;
  flex-wrap: wrap;
  opacity: .9;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,cccccc+100;Gren+3D */
  background: rgb(238,238,238); /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
}
.otherBox {
  padding-left: 80px;
}
/* child box container */
.childBox {
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  padding: 20px;
  background-color: white;
}
.child3 {
  padding-left: 50px !important;
}
.formButtonContainer {
  padding-top: 15px;
}
/* Child preferences */
.logFormContainer h6 {
  font-size: 12px;
  font-weight: 500;
}
.logLeftMarginLabel {
  margin-bottom: 14px;
}
.logFormContainer input,.logFormContainer select {
  font-size: 12px;
  font-weight: 500;
}
.logFormContainer .form-group {
  margin-bottom: 5px;
}
/* Image Gallery */
.imageGallery {
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #dddddd;
}
.imageContainer {
  margin: 15px;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #eeeeee;
}
.imageContainer.noPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 20px;
  text-align: center;
}
.imageContainer.noPreview:hover .download-overlay {
  opacity: 1;
  visibility: visible;
}
.imageContainer.noPreview .download-overlay {
  transition: all 0.2s ease-in;
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  width: 95%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
}
.loadingIcon {
  margin-left: 50px;
  margin-top: 50px;
}
.imageDeleteButton {
  width: 20px !important;
  border-spacing: 2px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  background-color: transparent !important;
  color: red;
  border-radius: 20px;
  opacity: 1;
}
.imageDeleteButton.noPreview {
  top: 0;
  left: 0;
}
.imageDeleteButton:hover {
  color: red;
  opacity: .8;
}
.imageDeleteButton:active {
  color: gray;
}
.imageObject {
  position: absolute;
  width: 190px;
  margin: 2.5px;
  height: 190px;
}

/* Checkbox */
.childBox input[type=checkbox]
{

  width: 30px; /*Desired width*/
  height: 30px; /*Desired height*/
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  box-shadow:none !important;
  border-color: #cccccc;
}
.childBox input[type="checkbox"]:checked {
  background-color: #EAA39D;
}
.childBox input[type="checkbox"]:checked:after {
  content: "\2713";
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 20px;
  left: 21px;
  bottom: 7px;
  position: absolute;
}
.childBox input[type="checkbox"]:focus{
  outline: 0 none;
  box-shadow: none;
}
.childBox input::-webkit-input-placeholder, .childBox textarea::-webkit-input-placeholder {
  color: gray;
  opacity: .5;
}
.childBox input::-ms-input-placeholder, .childBox textarea::-ms-input-placeholder {
  color: gray;
  opacity: .5;
}
.childBox input::placeholder, .childBox textarea::placeholder {
  color: gray;
  opacity: .5;
}
.childBoxSubsectionLabel {
  font-size: 14px;
  text-decoration: none !important;
}
.childBoxSubsectionIndent {
  margin-left: 15px;
}
.childBox h5 {
  text-decoration: underline;
}
.attachReceiptsLabel {
	margin-bottom	: 20px;
}

/* Grand total BOX */
.reportTotalBox {
  padding: 8px 20px 8px 20px;
  left: 50%;
  bottom: 30px;
  margin-top: -20px;
  margin-left: -110px;
}
.overlayLabelContainer {
  padding: 5px 15px 5px 15px;
}
.overlayTotalLabel {
  font-weight: light;
  font-size: 14px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 4px;
}
.overlayTotalValue {
  font-weight: 200;
  font-size: 14px;
  text-align: center;
}
.buttonContainer {
  padding-top: 15px;
  padding-left: 30px;
  margin-bottom: 15px;
}

/*  */
.logTableFootBitTitle {
  font-size: 20px;
 }
.logTableFootTitle {

}
.logTableFootValue {
  font-weight: 400;
}


/*  */
.detailedLogReportTotal {
  margin-left: 20px;
}

.filterContainer {
    margin: 0;
    max-width: 100%;
    background-color: white;
    border-bottom-color: #D7D7D7;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-top: 8px;
    padding-bottom: 8px;
}
/* Button */
.yearButton .btn-primary, .monthButton .btn-primary, .usersButton .btn-primary {
  background-color: #EAA39D;
  border-color: #F0F0F0;
  color: black;
  opacity: .9;
  margin-left: 20px;
  border-radius: 15px;
  border-width: 1px;
  padding: 0px 15px 0px 15px !important;
}
.filterContainer .dropdown-menu.show { padding: 0; }
.filterContainer .col-auto.col { padding: 0; }
/*  */
.yearButtonItem {
  margin-top: 2px;
}
.yearButtonItem, .monthButtonItem, .quarterButton, .usersButtonItem {
  padding: 0px 0px 0px 50px !important;
  background-color: white;
  color: black;
  font-size: 18px;
  font-weight: 500;
  border-color: #F0F0F0;
  box-shadow: none !important;
  border-bottom-color: #D7D7D7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
/*  */
.goButton {
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  margin-right: 50px;
  box-shadow:none !important;
}
.filterContainer .btn-primary:not(:disabled):not(.disabled).active,
.filterContainer .btn-primary:hover,
.filterContainer .btn-primary:not(:disabled):not(.disabled):active,
.filterContainer .show>.btn-primary.dropdown-toggle {
  background-color: #EAA39D;
  border-color: #F0F0F0;
  opacity: 1;
  color: black;
}

/* Table */
.ReactTable {
  border: none;
}

.multiButton {
  background-color: #EAA39D;
  border-color: #F0F0F0;
  color: black;
  opacity: .9;
  padding: 0px 15px 0px 15px !important;
  border-radius: 10px;
  border-width: 1px;
  margin: 0px !important;
  border-left-width: 0;
  border-right-width: 0;
}
.multiButton.active {
  background-color: #f47469 !important;
  box-shadow: none !important;
  outline:0 !important;
}

.buttonUserContainer {
  margin: 0;
  padding-top: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
}
.userDetailsForm {
  padding: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.userPageLabel {
  margin-left: -10px;
}

/* Checkbox */
.userDetailsForm input[type=checkbox]
{
  width: 30px; /*Desired width*/
  height: 30px; /*Desired height*/
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
       appearance: none;
  box-shadow:none !important;
  border-color: #cccccc;
}
.userDetailsForm input[type="checkbox"]:checked{
  background-color: #EAA39D;
}
.userDetailsForm input[type="checkbox"]:checked:after {
  content: "\2713";
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 20px;
  left: 21px;
  position: absolute;
}
.userDetailsForm input[type="checkbox"]:focus{
  outline: 0 none;
  box-shadow: none;
}

.formSettings {
  padding: 30px;
}
.buttonSettingsContainer {
  margin: 0;
  margin-top: -15px;
  margin-bottom: 15px;
}
h4 {
  font-size: 16px;
}

.logGaugeBackground {
  background-color: #eeeeee;
  padding: 15px;
  margin: 0 !important;
}
.logGaugeContainer {
  margin-right: 100px;
}
.logGaugeContainerBackground {
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
}
.logGaugeBar {
  border-radius: 10px;
  height: 60px;
  background-color: #dddbdb;
  width: calc(100% - 70px);
}
.logGaugeBarText {
  position: relative;
}
.logGaugeBarText h2 {
  font-size: 28px;
}
.logGaugeBarText h4 {
  font-size: 13px;
}
.logGaugeUsedBackground {
  position: absolute;
  border-radius: 10px;
  -webkit-animation: animateUsedBackground 1.5s ease-in-out;
  animation: animateUsedBackground 1.5s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes animateUsedBackground {
from {
  height: 60px;
  width: 0px;
}
to { height: 60px; }
}
@keyframes animateUsedBackground {
from {
  height: 60px;
  width: 0px;
}
to { height: 60px; }
}
.logGaugeMainLabel {
padding-top: 6px;
font-size: 38px;
font-weight: 600;
text-align: center;
color: #000000;
z-index: 99;
}
.logGaugeSecondaryLabel {
margin-top: -10px;
font-size: 16px;
font-weight: 600;
text-align: center;
color: #000000;
z-index: 99;
}
.logGaugeButtonContainer {
margin-left: 40px;
margin-right: 20px;
}

.tableDeleteButton,.tableEditButton,.tableResetPassButton {
  display: none;
  margin-left: 10px;
}
.tableDeleteButton {
}
/* Table */
.advLogTable input[type="checkbox"] {
  margin-left: 10px;
}

/* ADD BUTTON IN GAUGE */
.addLogActionContainer {
	width: 120px;
	display: flex;
	justify-content: space-between;
	margin-left: -28px;
}
.addLogActionContainer div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.addLogButton {
  width: 40px;
  height: 40px;
  border-radius: 20px;
	border-width: 2px;
	border-color: rgba(153, 153, 153, 0.35);
  font-size: 20px;
  padding: 0;
  background-color: #ffffff;
  color: #959494;
	text-align: center;
}
.addLogButton.noBorder {
  border: 0;
}
.addLogButton[disabled] {
	opacity: 0.3;
	cursor: not-allowed;
}
.addLogButton[disabled] + .addLogButtonText {
	opacity: 0.3;
}
.addLogButtonText {
  text-align: center;
	margin-top: 3px;
	font-weight: 500;
	font-size: 0.8em;
}
/* SUBMISSION BOX */
.logBoxSubmissionButton {
	display: inline-block;
	font-size: 10pt;
	font-weight: 500;
	border-radius: 12px;
	padding: 2px 10px 2px 10px;
	border-radius: 10px;
	border-width: 2px;
	background-color: #EAA39D;
	height: 30px;
	border-color: #ced4da !important;
	box-shadow:none !important;
}
.logBoxPrintButton {
	display: inline-block;
	font-size: 10pt;
	font-weight: 500;
	border-radius: 12px;
	padding: 2px 10px 2px 10px;
	border-radius: 10px;
	border-width: 2px;
	background-color: #EAA39D;
	height: 30px;
	border-color: #ced4da !important;
	box-shadow:none !important;
	margin-left: 10px;
}
.logButtonContainer {
  padding-top: 15px;
	padding-right: 15px;
}


.quarterViewChildContainer {
  margin-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}
.quarterViewButtonContainer {
  padding: 15px;
  padding-left: 0;
}
.quarterlyContainer {
  background-color: #EDECED;
  min-height: 100%;
}
.quarterlyFormContainer {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px
}
.formQButtonContainer {
  margin-bottom: 20px;
}
/* Tabs */
a.nav-item.nav-link {
  color: black;
  font-weight: 600;
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
}
a.nav-item.nav-link, a.nav-item.nav-link.active {
  text-decoration: none;
  border-color:  #e9ecef #e9ecef transparent !important;
}
.nav-tabs {
  border-bottom: none !important;
  border-width: 0px;
}
/* child box container */
.childBox {
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  padding: 20px;
  background-color: white;
  margin-bottom: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* Child preferences */
.quarterlyFormContainer h6 {
  font-size: 12px;
  font-weight: 500;
}
.quarterlyFormContainer h5 {
  font-size: 16px;
  font-weight: 600;
}
.quarterlyFormContainer input,.quarterlyFormContainer select {
  font-size: 12px;
  font-weight: 500;
}
.quarterlyFormContainer .form-group {
  margin-bottom: 5px;
}
.quarterlyLeftMarginLabel {
  margin-left: -30px;
  margin-top: 20px;
}
/* Info box */
.quarterlyInfoBox {
  border-color: #EDECED;
  background-color: #EDECED;
  border-radius: 10px;
  border-style: solid;
  margin-bottom: 20px;
}
.quarterlyInfoBoxContainer {
  margin: 10px;
  border-width: 10px;
  border-color: white;
  background-color: white;
  border-radius: 10px;
  border-style: solid;
}
.quarterlyInfoColumn {
  padding-top: 10px;
  text-align: center;
}
.quarterlyInfoLabel {
  font-size: 14px !important;
}
.quarterlyInfoValue {
  font-weight: 500 !important;
  font-size: 18px !important;
}
/* IM dynamic table */
.quarterlyIMHeaderContainer, .quarterlyIMEntriesContainer {
  padding: 10px 20px 10px 20px;
  border-bottom: 2px solid #eeeeee;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin: 0 -20px 0 -20px;
  text-align: center;
}
.quarterlyIMHeaderContainer h6 {
  font-size: 14px;
  font-weight: 600;
}
.quarterlyIMEntriesContainer {
  padding: 7px 20px 4px 20px;
}
.quarterlyIMOtherInfoContainer {
  padding-top: 20px;
}

.NotFound {
  padding-top: 100px;
  text-align: center;
}
body {
  padding: 0;
  min-width: 700px !important;
}
.wrapper {
  display: flex;
  flex-flow: column wrap;
  font-weight: bold;
  text-align: center;
  height: 100vh;

}
.wrapper > * {
  padding: 10px;
  flex: 1 1 100%;
}

/* main container - container nav and navigation controller */
.main {
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  background: white;
  flex: 1 0;
  padding: 0;
  order: 1;
}

/* #headers stuff */
.header {
  z-index: 999;
  width: auto;
  max-height: 90px;
  box-shadow: 0px 2px 0 0 rgba(138, 139, 138, 0.35);
  margin: 0;
}
.logo {
  background: url(/static/media/logo.c2cca9aa.png);
  background-size:     contain;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;
  float: left;
  width: 159px;
  height: 69px;
  margin-left: 20px;
}
/* User dropdown */
.buttonUserName {
  color: gray !important;
  font-size: 20px;
  padding-right: 30px ;
}
.buttonUserName::after {
  content: " \25BE";
}
.userPopover {
  margin-right: 20px !important;
}
.buttonUserName , .buttonUserName:hover, .buttonUserName:focus, .buttonUserName:active, .buttonUserName:visited,
.userPopover button, .userPopover button:hover, .userPopover button:focus, .userPopover button:active, .userPopover button:visited {
  background: transparent !important;
  border: none !important;
}
.userPopover .popover-body {
  padding: 0;
}
.userPopoverList .list-group-item {
  border: none;
  text-align: left;
  color: gray;
}
.userPopoverList .list-group-item:hover {
  cursor: pointer;
  opacity: .8;
  text-decoration: underline;
}

/* NavBar sutff */
.nav { order: 0; }
.navbar {
  z-index: 998;
  min-width: 180px;
  margin-top: 2px;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  box-shadow: 2px 0 0 0 rgba(138, 139, 138, 0.35);
}
.nav-item.active {
  text-decoration: underline;
  font-weight:500;
}
.nav-item:hover {
  text-decoration: underline;
  cursor: pointer;
}
.nav-item { font-weight: lighter; }
.navbar .nav-item,h3 { margin-left: 10px; }
.navbar h3 { margin-top: 5px; }
.navbar .nav-item { margin-top: 15px; }
/* .nav-span { flex-grow: 99; }
.navLogoutButton, .navLogoutButton:hover, .navLogoutButton:active:focus {
  background-color: transparent !important;
  border: none;
  color: black !important;
  margin-left: -15px;
  box-shadow: none !important;
}
.navLogoutButton:active:focus {
    position:relative;
    top:5px;
} */
/* Main app content */
.AppPageContainer {
  position: relative;
  flex: 1 0;
  height: auto;
  min-height: 100%;
  width: 100%;
  flex-direction: column
}

/*  PDF Stuff */
.PDFPageContainer {
  position: relative;
  flex: 1 0;
  height: auto;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
}
.PDFTable {
  width: 100%;
  zoom: .95;
}
.PDFQuarterlyTable {
  zoom: .62;
}
.PDFTable .rt-tr-group {
  page-break-inside: avoid;
  page-break-after: always;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 800px;
}
.alertContainer {
  z-index: 9990;
  position: fixed;
  width: auto;
  pointer-events: none;
}
.alert {
  width: auto;
  max-width: 500px;
  top:10px;
  max-height: 100px;
}
.alert .close {
  pointer-events: fill;
}

/* general react table  */
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton {
 display: inline-block;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton {
    background-color: white;
    color: #222222;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton {
    background-color: #EAA39D;
    color: #222222;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton:active,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton:active,
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton:active {
  color: gray;
}
.tableDeleteButton,.tableEditButton,.tableResetPassButton {
 display: none;
 font-size: 10pt;
 font-weight: 500;
 border-radius: 12px;
 padding: 2px 10px 2px 10px;
 border-radius: 10px;
 border-width: 1px;
 border-color: #ced4da !important;
 box-shadow:none !important;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}
.ReactTable .rt-th {
  align-self: center;
}
.ReactTable .rt-th, .ReactTable .rt-td {
  font-weight: normal;
  text-align: center;
}
.ReactTable .rt-tbody .rt-td {
  border-right: none;
}

/* Back and submit buttons */
.appBackBtn, .appSubmitBtn {
  border-radius: 13px;
  border-color: #cccccc;
  font-weight: 600;
  font-size: 16px;
  padding: 2px 20px 2px 20px;
}
.appBackBtn {
  background-color: #EAA39D;
}
.appSubmitBtn {
  background-color: white;
}
.appBackBtn:active, .appSubmitBtn:active {
  color: gray;
}
/* Form defaults */
.input-group-text {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

btn, btn-active, button, button:hover, button:focus, button:active, button:visited {
  box-shadow: none !important;
  outline:0 !important;
}

.noStepper input[type=number]::-webkit-inner-spin-button,
.noStepper input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

