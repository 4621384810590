.logGaugeBackground {
  background-color: #eeeeee;
  padding: 15px;
  margin: 0 !important;
}
.logGaugeContainer {
  margin-right: 100px;
}
.logGaugeContainerBackground {
  border-radius: 10px;
  padding: 10px;
  background-color: #ffffff;
}
.logGaugeBar {
  border-radius: 10px;
  height: 60px;
  background-color: #dddbdb;
  width: calc(100% - 70px);
}
.logGaugeBarText {
  position: relative;
}
.logGaugeBarText h2 {
  font-size: 28px;
}
.logGaugeBarText h4 {
  font-size: 13px;
}
.logGaugeUsedBackground {
  position: absolute;
  border-radius: 10px;
  -webkit-animation: animateUsedBackground 1.5s ease-in-out;
  -moz-animation: animateUsedBackground 1.5s ease-in-out;
  -o-animation: animateUsedBackground 1.5s ease-in-out;
  animation: animateUsedBackground 1.5s ease-in-out;
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  -o-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
@-webkit-keyframes animateUsedBackground {
from {
  height: 60px;
  width: 0px;
}
to { height: 60px; }
}
@-moz-keyframes animateUsedBackground {
from {
  height: 60px;
  width: 0px;
}
to { height: 60px; }
}
@-o-keyframes animateUsedBackground {
from {
  height: 60px;
  width: 0px;
}
to { height: 60px; }
}
@keyframes animateUsedBackground {
from {
  height: 60px;
  width: 0px;
}
to { height: 60px; }
}
.logGaugeMainLabel {
padding-top: 6px;
font-size: 38px;
font-weight: 600;
text-align: center;
color: #000000;
z-index: 99;
}
.logGaugeSecondaryLabel {
margin-top: -10px;
font-size: 16px;
font-weight: 600;
text-align: center;
color: #000000;
z-index: 99;
}
.logGaugeButtonContainer {
margin-left: 40px;
margin-right: 20px;
}
