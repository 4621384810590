.tableDeleteButton,.tableEditButton,.tableResetPassButton {
  display: none;
  margin-left: 10px;
}
.tableDeleteButton {
}
/* Table */
.advLogTable input[type="checkbox"] {
  margin-left: 10px;
}
