.multiButton {
  background-color: #EAA39D;
  border-color: #F0F0F0;
  color: black;
  opacity: .9;
  padding: 0px 15px 0px 15px !important;
  border-radius: 10px;
  border-width: 1px;
  margin: 0px !important;
  border-left-width: 0;
  border-right-width: 0;
}
.multiButton.active {
  background-color: #f47469 !important;
  box-shadow: none !important;
  outline:0 !important;
}
