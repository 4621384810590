body {
  padding: 0;
  min-width: 700px !important;
}
.wrapper {
  display: flex;
  flex-flow: column wrap;
  font-weight: bold;
  text-align: center;
  height: 100vh;

}
.wrapper > * {
  padding: 10px;
  flex: 1 100%;
}

/* main container - container nav and navigation controller */
.main {
  display: flex;
  flex-flow: row wrap;
  text-align: left;
  background: white;
  flex: 1 0;
  padding: 0;
  order: 1;
}

/* #headers stuff */
.header {
  z-index: 999;
  width: auto;
  max-height: 90px;
  box-shadow: 0px 2px 0 0 rgba(138, 139, 138, 0.35);
  margin: 0;
}
.logo {
  background: url('./../images/logo.png') ;
  background-size:     contain;                      /* <------ */
  background-repeat:   no-repeat;
  background-position: center center;
  float: left;
  width: 159px;
  height: 69px;
  margin-left: 20px;
}
/* User dropdown */
.buttonUserName {
  color: gray !important;
  font-size: 20px;
  padding-right: 30px ;
}
.buttonUserName::after {
  content: " ▾";
}
.userPopover {
  margin-right: 20px !important;
}
.buttonUserName , .buttonUserName:hover, .buttonUserName:focus, .buttonUserName:active, .buttonUserName:visited,
.userPopover button, .userPopover button:hover, .userPopover button:focus, .userPopover button:active, .userPopover button:visited {
  background: transparent !important;
  border: none !important;
}
.userPopover .popover-body {
  padding: 0;
}
.userPopoverList .list-group-item {
  border: none;
  text-align: left;
  color: gray;
}
.userPopoverList .list-group-item:hover {
  cursor: pointer;
  opacity: .8;
  text-decoration: underline;
}

/* NavBar sutff */
.nav { order: 0; }
.navbar {
  z-index: 998;
  min-width: 180px;
  margin-top: 2px;
  justify-content: flex-start;
  align-items: flex-start;
  background: white;
  box-shadow: 2px 0 0 0 rgba(138, 139, 138, 0.35);
}
.nav-item.active {
  text-decoration: underline;
  font-weight:500;
}
.nav-item:hover {
  text-decoration: underline;
  cursor: pointer;
}
.nav-item { font-weight: lighter; }
.navbar .nav-item,h3 { margin-left: 10px; }
.navbar h3 { margin-top: 5px; }
.navbar .nav-item { margin-top: 15px; }
/* .nav-span { flex-grow: 99; }
.navLogoutButton, .navLogoutButton:hover, .navLogoutButton:active:focus {
  background-color: transparent !important;
  border: none;
  color: black !important;
  margin-left: -15px;
  box-shadow: none !important;
}
.navLogoutButton:active:focus {
    position:relative;
    top:5px;
} */
/* Main app content */
.AppPageContainer {
  position: relative;
  flex: 1 0;
  height: auto;
  min-height: 100%;
  width: 100%;
  flex-direction: column
}

/*  PDF Stuff */
.PDFPageContainer {
  position: relative;
  flex: 1 0;
  height: auto;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
}
.PDFTable {
  width: 100%;
  zoom: .95;
}
.PDFQuarterlyTable {
  zoom: .62;
}
.PDFTable .rt-tr-group {
  page-break-inside: avoid;
  page-break-after: always;
}
