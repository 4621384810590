/* ADD BUTTON IN GAUGE */
.addLogActionContainer {
	width: 120px;
	display: flex;
	justify-content: space-between;
	margin-left: -28px;
}
.addLogActionContainer div {
	display: flex;
	flex-direction: column;
	align-items: center;
}
.addLogButton {
  width: 40px;
  height: 40px;
  border-radius: 20px;
	border-width: 2px;
	border-color: rgba(153, 153, 153, 0.35);
  font-size: 20px;
  padding: 0;
  background-color: #ffffff;
  color: #959494;
	text-align: center;
}
.addLogButton.noBorder {
  border: 0;
}
.addLogButton[disabled] {
	opacity: 0.3;
	cursor: not-allowed;
}
.addLogButton[disabled] + .addLogButtonText {
	opacity: 0.3;
}
.addLogButtonText {
  text-align: center;
	margin-top: 3px;
	font-weight: 500;
	font-size: 0.8em;
}
/* SUBMISSION BOX */
.logBoxSubmissionButton {
	display: inline-block;
	font-size: 10pt;
	font-weight: 500;
	border-radius: 12px;
	padding: 2px 10px 2px 10px;
	border-radius: 10px;
	border-width: 2px;
	background-color: #EAA39D;
	height: 30px;
	border-color: #ced4da !important;
	box-shadow:none !important;
}
.logBoxPrintButton {
	display: inline-block;
	font-size: 10pt;
	font-weight: 500;
	border-radius: 12px;
	padding: 2px 10px 2px 10px;
	border-radius: 10px;
	border-width: 2px;
	background-color: #EAA39D;
	height: 30px;
	border-color: #ced4da !important;
	box-shadow:none !important;
	margin-left: 10px;
}
.logButtonContainer {
  padding-top: 15px;
	padding-right: 15px;
}
