/* Grand total BOX */
.reportTotalBox {
  padding: 8px 20px 8px 20px;
  left: 50%;
  bottom: 30px;
  margin-top: -20px;
  margin-left: -110px;
}
.overlayLabelContainer {
  padding: 5px 15px 5px 15px;
}
.overlayTotalLabel {
  font-weight: light;
  font-size: 14px;
  text-align: center;
  margin-right: 20px;
  margin-bottom: 4px;
}
.overlayTotalValue {
  font-weight: 200;
  font-size: 14px;
  text-align: center;
}
.buttonContainer {
  padding-top: 15px;
  padding-left: 30px;
  margin-bottom: 15px;
}

/*  */
.logTableFootBitTitle {
  font-size: 20px;
 }
.logTableFootTitle {

}
.logTableFootValue {
  font-weight: 400;
}


/*  */
.detailedLogReportTotal {
  margin-left: 20px;
}
