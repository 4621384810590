.formSettings {
  padding: 30px;
}
.buttonSettingsContainer {
  margin: 0;
  margin-top: -15px;
  margin-bottom: 15px;
}
h4 {
  font-size: 16px;
}
