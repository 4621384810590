.filterContainer {
    margin: 0;
    max-width: 100%;
    background-color: white;
    border-bottom-color: #D7D7D7;
    border-bottom-width: 2px;
    border-bottom-style: solid;
    padding-top: 8px;
    padding-bottom: 8px;
}
/* Button */
.yearButton .btn-primary, .monthButton .btn-primary, .usersButton .btn-primary {
  background-color: #EAA39D;
  border-color: #F0F0F0;
  color: black;
  opacity: .9;
  margin-left: 20px;
  border-radius: 15px;
  border-width: 1px;
  padding: 0px 15px 0px 15px !important;
}
.filterContainer .dropdown-menu.show { padding: 0; }
.filterContainer .col-auto.col { padding: 0; }
/*  */
.yearButtonItem {
  margin-top: 2px;
}
.yearButtonItem, .monthButtonItem, .quarterButton, .usersButtonItem {
  padding: 0px 0px 0px 50px !important;
  background-color: white;
  color: black;
  font-size: 18px;
  font-weight: 500;
  border-color: #F0F0F0;
  box-shadow: none !important;
  border-bottom-color: #D7D7D7;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}
/*  */
.goButton {
  font-size: 10pt;
  font-weight: 500;
  border-radius: 12px;
  padding: 0px 10px 0px 10px;
  border-radius: 10px;
  border-width: 1px;
  border-color: #ced4da !important;
  margin-right: 50px;
  box-shadow:none !important;
}
.filterContainer .btn-primary:not(:disabled):not(.disabled).active,
.filterContainer .btn-primary:hover,
.filterContainer .btn-primary:not(:disabled):not(.disabled):active,
.filterContainer .show>.btn-primary.dropdown-toggle {
  background-color: #EAA39D;
  border-color: #F0F0F0;
  opacity: 1;
  color: black;
}

/* Table */
.ReactTable {
  border: none;
}
