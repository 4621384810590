.AsyncContainer {
  background-color: #EDECED;
  min-height: 100%;
}
.logContainer {
  background-color: #EDECED;
  min-height: 100%;
}
.logFormContainer {
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 20px;
}
.formLabel {
  padding-top: 15px;
  padding-left: 10px;
  margin-bottom: 10px;
	font-size: 20px !important;
}
a.nav-item.nav-link {
  color: black;
  font-weight: 600;
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
}
a.nav-item.nav-link.active {
  text-decoration: none;
  border-color:  #e9ecef #e9ecef transparent !important;
}
.nav-tabs {
  border-bottom: none !important;
  border-width: 0px;
}
/* Custom column sizing */
.custcol-1-half-left-fix {
  margin-left: 19%;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 13.5% !important;
  flex: 0 0 13.5% !important;
  max-width: 13.5% !important;
}
.custcol-1-half {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 12% !important;
    flex: 0 0 12% !important;
    max-width: 12% !important;
}
.overlined-col {
  border-top-width: 2px ;
  border-color: gray;
  padding-top: 4px;
  border-top-style: solid;
}
.overlined-col-last {
  border-top-width: 2px ;
  border-color: gray;
  padding-top: 4px;
  border-top-style: solid;
  padding-right: 50px;
}
.overlined-col-first {
  border-top-width: 2px ;
  border-color: gray;
  padding-top: 4px;
  border-top-style: solid;
  padding-left: 60px;
}
.childBoxPlusSign {
  position: absolute;
  right: 47%;
  text-decoration: none !important;
  color: gray;
  font-size: 45px;
  font-weight: 600;
  bottom: -52px;
  z-index: 999;
}
.childBoxEqualSign {
  position: absolute;
  right: 40%;
  text-decoration: none !important;
  color: gray;
  font-size: 45px;
  font-weight: 600;
  bottom: -52px;
  z-index: 999;
}
.total-col  {
  align-self: flex-end;
}
.childBoxCalculationBox {
  margin-bottom: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  border-width: 1px;
  border-color: gray;
  border-style: solid;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  opacity: .9;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,cccccc+100;Gren+3D */
  background: rgb(238,238,238); /* Old browsers */
  background: -moz-linear-gradient(top, rgba(238,238,238,1) 0%, rgba(204,204,204,1) 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, rgba(238,238,238,1) 0%,rgba(204,204,204,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
}
.otherBox {
  padding-left: 80px;
}
/* child box container */
.childBox {
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  padding: 20px;
  background-color: white;
}
.child3 {
  padding-left: 50px !important;
}
.formButtonContainer {
  padding-top: 15px;
}
/* Child preferences */
.logFormContainer h6 {
  font-size: 12px;
  font-weight: 500;
}
.logLeftMarginLabel {
  margin-bottom: 14px;
}
.logFormContainer input,.logFormContainer select {
  font-size: 12px;
  font-weight: 500;
}
.logFormContainer .form-group {
  margin-bottom: 5px;
}
/* Image Gallery */
.imageGallery {
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #dddddd;
}
.imageContainer {
  margin: 15px;
  width: 200px;
  height: 200px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  border-color: #eeeeee;
}
.imageContainer.noPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 20px;
  text-align: center;
}
.imageContainer.noPreview:hover .download-overlay {
  opacity: 1;
  visibility: visible;
}
.imageContainer.noPreview .download-overlay {
  transition: all 0.2s ease-in;
  position: absolute;
  background: rgba(255, 255, 255, 0.95);
  width: 95%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
}
.loadingIcon {
  margin-left: 50px;
  margin-top: 50px;
}
.imageDeleteButton {
  width: 20px !important;
  border-spacing: 2px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  position: absolute;
  background-color: transparent !important;
  color: red;
  border-radius: 20px;
  opacity: 1;
}
.imageDeleteButton.noPreview {
  top: 0;
  left: 0;
}
.imageDeleteButton:hover {
  color: red;
  opacity: .8;
}
.imageDeleteButton:active {
  color: gray;
}
.imageObject {
  position: absolute;
  width: 190px;
  margin: 2.5px;
  height: 190px;
}

/* Checkbox */
.childBox input[type=checkbox]
{

  width: 30px; /*Desired width*/
  height: 30px; /*Desired height*/
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  box-shadow:none !important;
  border-color: #cccccc;
}
.childBox input[type="checkbox"]:checked {
  background-color: #EAA39D;
}
.childBox input[type="checkbox"]:checked:after {
  content: "✓";
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 20px;
  left: 21px;
  bottom: 7px;
  position: absolute;
}
.childBox input[type="checkbox"]:focus{
  outline: 0 none;
  box-shadow: none;
}
.childBox input::placeholder, .childBox textarea::placeholder {
  color: gray;
  opacity: .5;
}
.childBoxSubsectionLabel {
  font-size: 14px;
  text-decoration: none !important;
}
.childBoxSubsectionIndent {
  margin-left: 15px;
}
.childBox h5 {
  text-decoration: underline;
}
.attachReceiptsLabel {
	margin-bottom	: 20px;
}
