.quarterViewChildContainer {
  margin-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}
.quarterViewButtonContainer {
  padding: 15px;
  padding-left: 0;
}
.quarterlyContainer {
  background-color: #EDECED;
  min-height: 100%;
}
.quarterlyFormContainer {
  padding-top: 15px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px
}
.formQButtonContainer {
  margin-bottom: 20px;
}
/* Tabs */
a.nav-item.nav-link {
  color: black;
  font-weight: 600;
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  border-top-left-radius: .4rem;
  border-top-right-radius: .4rem;
}
a.nav-item.nav-link, a.nav-item.nav-link.active {
  text-decoration: none;
  border-color:  #e9ecef #e9ecef transparent !important;
}
.nav-tabs {
  border-bottom: none !important;
  border-width: 0px;
}
/* child box container */
.childBox {
  border-color:  #e9ecef #e9ecef #dee2e6 !important;
  border-width: 1px !important;
  border-style: solid;
  padding: 20px;
  background-color: white;
  margin-bottom: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
/* Child preferences */
.quarterlyFormContainer h6 {
  font-size: 12px;
  font-weight: 500;
}
.quarterlyFormContainer h5 {
  font-size: 16px;
  font-weight: 600;
}
.quarterlyFormContainer input,.quarterlyFormContainer select {
  font-size: 12px;
  font-weight: 500;
}
.quarterlyFormContainer .form-group {
  margin-bottom: 5px;
}
.quarterlyLeftMarginLabel {
  margin-left: -30px;
  margin-top: 20px;
}
/* Info box */
.quarterlyInfoBox {
  border-color: #EDECED;
  background-color: #EDECED;
  border-radius: 10px;
  border-style: solid;
  margin-bottom: 20px;
}
.quarterlyInfoBoxContainer {
  margin: 10px;
  border-width: 10px;
  border-color: white;
  background-color: white;
  border-radius: 10px;
  border-style: solid;
}
.quarterlyInfoColumn {
  padding-top: 10px;
  text-align: center;
}
.quarterlyInfoLabel {
  font-size: 14px !important;
}
.quarterlyInfoValue {
  font-weight: 500 !important;
  font-size: 18px !important;
}
/* IM dynamic table */
.quarterlyIMHeaderContainer, .quarterlyIMEntriesContainer {
  padding: 10px 20px 10px 20px;
  border-bottom: 2px solid #eeeeee;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  margin: 0 -20px 0 -20px;
  text-align: center;
}
.quarterlyIMHeaderContainer h6 {
  font-size: 14px;
  font-weight: 600;
}
.quarterlyIMEntriesContainer {
  padding: 7px 20px 4px 20px;
}
.quarterlyIMOtherInfoContainer {
  padding-top: 20px;
}
