.ReactNavigationControllerView,
.ReactNavigationControllerViewContent {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  display: inherit;
}

.ReactNavigationControllerView {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}
.ReactNavigationController {
  position: relative;
  flex: 1 0;
}
.ReactNavigationControllerViewContent {
  color: white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
      -ms-flex-direction: column;
          flex-direction: column;
}
