.buttonUserContainer {
  margin: 0;
  padding-top: 15px;
  margin-left: 15px;
  margin-bottom: 15px;
}
.userDetailsForm {
  padding: 30px;
  padding-left: 20px;
  padding-right: 20px;
}
.userPageLabel {
  margin-left: -10px;
}

/* Checkbox */
.userDetailsForm input[type=checkbox]
{
  width: 30px; /*Desired width*/
  height: 30px; /*Desired height*/
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
  box-shadow:none !important;
  border-color: #cccccc;
}
.userDetailsForm input[type="checkbox"]:checked{
  background-color: #EAA39D;
}
.userDetailsForm input[type="checkbox"]:checked:after {
  content: "✓";
  font-family: 'Helvetica', 'Arial', sans-serif;
  font-weight: bold;
  font-size: 20px;
  left: 21px;
  position: absolute;
}
.userDetailsForm input[type="checkbox"]:focus{
  outline: 0 none;
  box-shadow: none;
}
