body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-width: 800px;
}
.alertContainer {
  z-index: 9990;
  position: fixed;
  width: auto;
  pointer-events: none;
}
.alert {
  width: auto;
  max-width: 500px;
  top:10px;
  max-height: 100px;
}
.alert .close {
  pointer-events: fill;
}

/* general react table  */
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton {
 display: inline-block;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton {
    background-color: white;
    color: #222222;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton {
    background-color: #EAA39D;
    color: #222222;
}
.ReactTable .rt-tbody .rt-tr-group:hover .tableResetPassButton:active,
.ReactTable .rt-tbody .rt-tr-group:hover .tableDeleteButton:active,
.ReactTable .rt-tbody .rt-tr-group:hover .tableEditButton:active {
  color: gray;
}
.tableDeleteButton,.tableEditButton,.tableResetPassButton {
 display: none;
 font-size: 10pt;
 font-weight: 500;
 border-radius: 12px;
 padding: 2px 10px 2px 10px;
 border-radius: 10px;
 border-width: 1px;
 border-color: #ced4da !important;
 box-shadow:none !important;
}
.ReactTable .rt-thead .rt-th, .ReactTable .rt-thead .rt-td {
  border-right: none;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: 600;
}
.ReactTable .rt-th {
  align-self: center;
}
.ReactTable .rt-th, .ReactTable .rt-td {
  font-weight: normal;
  text-align: center;
}
.ReactTable .rt-tbody .rt-td {
  border-right: none;
}

/* Back and submit buttons */
.appBackBtn, .appSubmitBtn {
  border-radius: 13px;
  border-color: #cccccc;
  font-weight: 600;
  font-size: 16px;
  padding: 2px 20px 2px 20px;
}
.appBackBtn {
  background-color: #EAA39D;
}
.appSubmitBtn {
  background-color: white;
}
.appBackBtn:active, .appSubmitBtn:active {
  color: gray;
}
/* Form defaults */
.input-group-text {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

btn, btn-active, button, button:hover, button:focus, button:active, button:visited {
  box-shadow: none !important;
  outline:0 !important;
}

.noStepper input[type=number]::-webkit-inner-spin-button,
.noStepper input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
